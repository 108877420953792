
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: 'XSYJCGMC',
                    label: '学术研究成果名称',
                    minWidth: 135
                }, {
                    prop: 'YJFX',
                    label: '研究方向',
                    minWidth: 128,
                }, {
                    prop: 'XSYJCGLB',
                    label: '学术研究成果类别',
                    minWidth: 128,
                }, {
                    prop: 'LJ',
                    label: '文件',
                    minWidth: 90,
                    showFileBtn: true,
                },
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
};
export default selfData;